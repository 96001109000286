import { defineStore } from 'pinia';
import $http from '@/http';
import { base64_decode } from '@/tools/base64Decode';

const useAppStore = defineStore({
  id: 'app',

  state: () => ({
    appConfig: {},
  }),

  getters: {
    configInfo(state) {
      const defaultConfig = {
        appName: '',
        companyName: '',
        address: '',
        logo: '',
        companyPhone: '',
        appHost: '',
        icpInfo: '',
        webHost: '',
      };

      let configList = Object.keys(state.appConfig);
      let host = location.host;

      if (configList.length) {
        // 本地开发
        if (process.env.NODE_ENV === 'development') {
          return {
            appName: state.appConfig[configList[1]].appname,
            companyName: state.appConfig[configList[1]].company,
            address: state.appConfig[configList[1]].address,
            logo: state.appConfig[configList[1]].applogo,
            companyPhone: state.appConfig[configList[1]].companyphone,
            appHost: state.appConfig[configList[1]].app_host,
            icpInfo: state.appConfig[configList[1]].icp_info,
            webHost: state.appConfig[configList[1]].web_host,
          };
        }

        let currentUrl = configList.find((key) => {
          return key.includes(host);
        });

        if (currentUrl) {
          return {
            appName: state.appConfig[currentUrl].appname,
            companyName: state.appConfig[currentUrl].company,
            address: state.appConfig[currentUrl].address,
            logo: state.appConfig[currentUrl].applogo,
            companyPhone: state.appConfig[currentUrl].companyphone,
            appHost: state.appConfig[currentUrl].app_host,
            icpInfo: state.appConfig[currentUrl].icp_info,
            webHost: state.appConfig[currentUrl].web_host,
          };
        } else {
          return defaultConfig;
        }
      } else {
        return defaultConfig;
      }
    },
  },

  actions: {
    _getAppConfig() {
      return $http.get('api/global/appsys/get_app_sysweb').then((res) => {
        this.appConfig = JSON.parse(base64_decode(res.data.data));
      });
    },
  },
});

export default useAppStore;
