<script setup>
import { watchEffect } from 'vue';
import useAppStore from '@/store/app';

const appStore = useAppStore();

watchEffect(() => {
  // appStore._getAppConfig().then(() => {
  //   document.title = appStore.configInfo.appName;
  //   let favicon = document.querySelector('link[rel="icon"]');
  //   if (favicon) {
  //     favicon.href = appStore.configInfo.logo;
  //   } else {
  //     favicon = document.createElement('link');
  //     favicon.rel = 'icon';
  //     favicon.href = appStore.configInfo.logo;
  //     document.head.appendChild(favicon);
  //   }
  // });
});
</script>

<template>
  <div class="app">
    <RouterView />
  </div>
</template>

<style scoped lang="less">
.app {
  height: 100%;
}
</style>

<style lang="less">
#app {
  height: 100%;
}
</style>
